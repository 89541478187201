import Layout from 'components/Layout';
import Button from 'components/UI/Button';
import styles from './styles.module.scss';
import { delToken } from 'models/user';
import { useKeycloak } from '@react-keycloak/web';


export default function Forbidden({text}: {text: string|null}) {
    
	const { keycloak } = useKeycloak();

	const handleLogout = () => {
		localStorage.clear();
		sessionStorage.clear();
		document.cookie.split(";").forEach(cookie => {
			document.cookie = cookie
			  .replace(/^ +/, "")
			  .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
		});		
        delToken()
        keycloak.logout(); 
	}
	
	return (
		<Layout>
			<div className={`${styles.forbidden} row`}>
				<div className='column-md-7'>{text ? text : 'У вас недостаточно прав для получения данной функциональности. Обратитесь в Сервис деск компании.'}</div>
			</div>
		</Layout>
	)
	
};