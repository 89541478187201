import React, { Dispatch } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Select, { ISelectOptions } from 'components/UI/Select';
import { IPosition, OutsourceRolesEnum } from 'types/users';
import AsyncSelect from 'components/UI/AsyncSelect';
import { convertUser } from 'utils/helpers';
import useFetch from 'use-http';
import { ICounterparty } from 'types/counterparty';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

interface IData {
	id: string;
	title: string;
}

interface IOption {
    value: string;
    label: string;
}

export const convertToOption = (data: IData[]): IOption[] => {
	return data?.length ? data.map((item: IData) => {
		return {
			value: String(item.id),
			label: item.title,
		};
	}) : [];
};
interface IForm<T> {
	row: T;
	init: () => Promise<void>;
	update: (data: FormInputs) => Promise<void>;
	setDisplay: Dispatch<boolean>;
}

export interface FormInputs {
	userId?: string;
	firstname: string;
	secondname: string;
	middlename: string;
	document_series: string;
	document_number: string;
	counterparty?: string;
	counterpartyId?: string;
	position: string;
	positionId?: string;
}

const UserForm = ({onSubmit, loadingData, defaultValues, mode = 'add', short = false} : {onSubmit: SubmitHandler<FormInputs>, loadingData?:boolean, defaultValues?: FormInputs; mode?: string, short?: boolean}) => {
	const { get, loading, error } = useFetch();
	const navigate = useNavigate();

	const [counterparty, setCounterparty] = React.useState('')
	const [initLoad, setInitLoad] = React.useState(false)

	const [counterpartyList, setCounterpartyList] = React.useState([]);
	const [positionOptions, setPositionOptions] = React.useState<IOption[]>([]);
	const { handleSubmit, control, register, watch, setValue, formState, trigger, formState: { errors  }} = useForm<FormInputs>({ defaultValues });

	React.useEffect(() => {
		init();
	}, []);

	async function init() {
		const resultCounterparty = await get(`/api/counterparty`);
		setCounterpartyList(resultCounterparty);
		setInitLoad(true)
		loadPosition()
	}

	async function loadPosition() {
		const data: IPosition[] = await get(`/api/positions`);
		const filterPosition = data.filter(pos => pos.position_id)
		const options: IOption[] = convertToOption(filterPosition)
		return setPositionOptions(options)
	}	

	async function loadCounterparty(inputValue: string, callback: (options: ISelectOptions[]) => void) {
		let data = [];
		const params = new URLSearchParams({
			title: inputValue,
		});
		if (inputValue) {
			data = await get(`/api/counterparty?${params.toString()}`);
		}

		return convertToOption(data)
	}

	if (error) {
		return (
			<>{error}</>
		)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
			<div className="row">
				<div className="column-xs-12">
						<Controller
							name={`firstname`}
							control={control}
							rules={{ required: 'Обязательное поле' }}
							render={({ field, fieldState }) => {
								return (
									<Input
										onChange={field.onChange}
										label={<span>Фамилия<span style={{ color: 'red' }}>*</span></span>}
										onBlur={field.onBlur}
										name={field.name}
										error={fieldState.error}
										defaultValue={field.value}
									/>
								)
							}}
						/>
				</div>
				<div className="column-xs-12">
						<Controller
							name={`secondname`}
							control={control}
							rules={{ required: 'Обязательное поле' }}
							render={({ field, fieldState }) => {
								return (
									<Input
										onChange={field.onChange}
										label={<span>Имя <span style={{ color: 'red' }}>*</span></span>}
										onBlur={field.onBlur}
										name={field.name}
										error={fieldState.error}
										defaultValue={field.value}
									/>
								)
							}}
						/>
				</div>
				<div className="column-xs-12">
						<Controller
							name={`middlename`}
							control={control}
							render={({ field, fieldState }) => {
								return (
									<Input
										onChange={field.onChange}
										label='Отчество'
										onBlur={field.onBlur}
										name={field.name}
										error={fieldState.error}
										defaultValue={field.value}
									/>
								)
							}}
						/>
				</div>								
				<div className="column-xs-12">
					{!watch('userId') && <Controller
						name={`document_series`}
						control={control}
						render={({ field, fieldState }) => (
							<Input
								onChange={field.onChange}
								onBlur={field.onBlur}
								label='Серия удостоверения'
								name={field.name}
								error={fieldState.error}
								defaultValue={field.value}
							/>
						)}
					/>}
				</div>
				<div className="column-xs-12">
					{!watch('userId') && <Controller
						name={`document_number`}
						control={control}
						rules={{ required: 'Обязательное поле' }}
						render={({ field, fieldState }) => (
							<Input
								onChange={field.onChange}
								onBlur={field.onBlur}
								label={<span>Номер удостоверения<span style={{ color: 'red' }}>*</span></span>}
								name={field.name}
								error={fieldState.error}
								defaultValue={field.value}
							/>
						)}
					/>}
				</div>				
				{!short && mode !==  'edit' && (
					<div className="column-xs-12">
						{initLoad && <Controller
							name={`counterpartyId`}
							control={control}
							rules={{ required: 'Обязательное поле' }}
							render={({ field, fieldState }) => {
								return (
									<AsyncSelect 
										onChange={(v:any) => {
											setCounterparty('')
											setValue('counterparty', '')
											field.onChange(v)
										}}
										onInputChange={(inputValue, actionMeta) => {
											if (actionMeta.action === 'input-change') {
												setCounterparty(inputValue)
											}
										}}
										innerRef={field.ref}
										load={loadCounterparty}
										error={fieldState.error}
										label={<span>Контрагент<span style={{ color: 'red' }}>*</span></span>}
										inputValue={counterparty}
										isClearable
										defaultOptions={convertToOption(counterpartyList)}
										defaultValue={field.value}
									/>
								)
							}}
						/>}
					</div>
				)}
				{positionOptions.length > 0 && !short && mode !==  'edit' && (
					<div className="column-xs-12">
						<Controller
							name={`positionId`}
							control={control}
							rules={{ required: 'Обязательное поле' }}
							render={({ field, fieldState }) => {
								return (
									<Select
										onChange={field.onChange}
										options={positionOptions}
										label={<span>Должность<span style={{ color: 'red' }}>*</span></span>}
										error={fieldState.error}
										defaultValue={field.value}
									/>
								)
							}}
						/>
					</div>
				)}
				<div className="column-xs-12">
					<div className="row">
						<div className="column-xs-6">
							<Button variant="violet" onClick={() => navigate('/qrcode')} style={{margin:'auto'}}>
								Назад
							</Button>
						</div>
						<div className="column-xs-6">
							<Button type="submit" style={{margin:'auto'}} disabled={loadingData}>
								{loadingData ? "Загрузка..." : "Подтвердить"}
							</Button>
						</div>						
					</div>
				</div>
			</div>
		</form>
	);
};

export default UserForm;
