import { useState, useEffect } from 'react';

interface IGeoError {
	code: number;
	message: string;
	PERMISSION_DENIED: number;
	POSITION_UNAVAILABLE: number;
	TIMEOUT: number;
}

class GeoCoords {
	latitude?: number;
	longitude?: number;
	altitude?: number | null;
	accuracy?: number | null;
	altitudeAccuracy?: number | null;
	heading?: number | null;
	speed?: number | null;
}

const TIME = 60000

export const usePosition = () => {
	const [position, setPosition] = useState<GeoCoords | null>(null);
	const [error, setError] = useState<string | null>(null);
	const RETRY_TIME = 60000; // 60 секунд
	const POLLING_INTERVAL = 1000; // 1 секунда
  
	const handleSuccess = ({ coords }: { coords: GeoCoords }) => {
	  if (coords.latitude && coords.longitude) {
		setPosition({
		  latitude: coords.latitude,
		  longitude: coords.longitude,
		});
	  } else {
		setError("Координаты пустые. Продолжаем попытки получить данные.");
	  }
	};
  
	const handleError = (err: IGeoError) => {
	  switch (err.code) {
		case err.PERMISSION_DENIED:
		  setError("Пользователь запретил доступ к геолокации.");
		  break;
		case err.POSITION_UNAVAILABLE:
		  setError("Информация о местоположении недоступна.");
		  break;
		case err.TIMEOUT:
		  setError("Время ожидания истекло.");
		  break;
		default:
		  setError("Произошла неизвестная ошибка.");
		  break;
	  }
	};
  
	const requestPosition = () => {
	  const geo = navigator.geolocation;
	  if (!geo) {
		setError("Информация о местоположении недоступна.");
		return;
	  }
  
	  geo.getCurrentPosition(handleSuccess, handleError, {});
	};
  
	useEffect(() => {
	  const geo = navigator.geolocation;
	  if (!geo) {
		setError("Geolocation is not supported");
		return;
	  }
  
	  let elapsedTime = 0;
	  const intervalId = setInterval(() => {
		console.log({position})
		if (position) {
		  clearInterval(intervalId);
		  return;
		}
		if (elapsedTime >= RETRY_TIME) {
		  clearInterval(intervalId);
		  return;
		}
		requestPosition();
		elapsedTime += POLLING_INTERVAL;
	  }, POLLING_INTERVAL);
  
	  return () => {
		clearInterval(intervalId);
	  };
	}, [position]);
  
	return { position, error, requestPosition };
  };
  