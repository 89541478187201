import React from 'react';
import styles from './styles.module.scss';
import UserForm, { FormInputs } from './UserForm';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from 'use-http';
import { IOutsourcing } from 'types/outsourcing';


export default function EditUser() {

	const navigate = useNavigate();
    const { outsourceId } = useParams();
    const [defaultValues, setDefaultValues] = React.useState<FormInputs|undefined>()

    const { get: fetchData1, loading: loading1 } = useFetch(`api/outsourcing`);
    const { patch: fetchData2, loading: loading2 } = useFetch(`api/users`);
    const { patch: fetchData3, loading: loading3 } = useFetch(`/api/outsourcing`);    

    const [outsourcing, setOutsourcing] = React.useState<IOutsourcing>()

    const loadOutsourcing = React.useCallback(async () => {
        if (outsourceId) {
            const outsourcing = await fetchData1(outsourceId);
            if (outsourcing?.id) {
                setDefaultValues({
                    firstname: outsourcing.user?.firstname,
                    secondname: outsourcing.user?.secondname,
                    middlename: outsourcing.user?.middlename,
                    document_series: outsourcing.user?.document_series,
                    document_number: outsourcing.user?.document_number,
                    counterpartyId: outsourcing.counterpartyId,
                    position: outsourcing.position
                })
                setOutsourcing(outsourcing)
            }
        }
	}, [outsourceId]);

    React.useEffect(() => {
        loadOutsourcing()
    }, [])    

	const handleSubmit = async (data:any) => {
        if (
            outsourcing?.user?.firstname !== data.firstname ||
            outsourcing?.user?.secondname !== data.secondname ||
            outsourcing?.user?.middlename !== data.middlename ||
            outsourcing?.user?.document_series !== data.document_series ||
            outsourcing?.user?.document_number !== data.document_number
        ) {
            await fetchData2(outsourcing?.user?.id, {
                firstname: data.firstname,
                secondname: data.secondname,
                middlename: data.middlename,
                document_series: data.document_series,
                document_number: data.document_number,
            })
        }

        await fetchData3(`${outsourcing?.id}`, {
            position: data.position,
            counterpartyId: data.counterpartyId,
        })

		return navigate(`/outsourcing`);
	};

    const isLoading = loading1 || loading2 || loading3;

	return (
		<div className={styles["section-list"]}>
            <h2>Заполните обязательные поля</h2>
            {defaultValues && <UserForm onSubmit={handleSubmit} loadingData={isLoading} defaultValues={defaultValues} mode="edit" />}
        </div>
    )
}