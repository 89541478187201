import * as React from 'react'
import Layout from 'components/Layout';
import styles from './styles.module.scss';
import Button from 'components/UI/Button'
import { $noCoord, setNoCoord } from 'models/user'
import { useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
const isMobile = /Mobi|Android/i.test(window.navigator.userAgent)
const isTDC = /DT40/i.test(window.navigator.userAgent)

export default function GeoError({message}: {message: string}) {
    const navigate = useNavigate();
    const noCoord = useUnit($noCoord);  
    const encodedRef = location.pathname

    const handleClick = () => {
        setNoCoord(true);
        navigate(encodedRef);
    };

    //TODO refact
    React.useEffect(() => {
        if ((!isMobile || isTDC) && !noCoord) {
            handleClick();
        }
    }, [isMobile, isTDC, setNoCoord, noCoord])
    
    return (
        <Layout >
            <div style={{ textAlign: 'center' }} className={styles['geo-err-body']}>
                {message && <div>{`Ошибка во время получения геоданных: ${message}`}</div>}<br />
                
                <a target="_blank" href="/setup_geo.pdf" rel="noreferrer">
                    ссылка как настроить
                </a>
                <div>
                    В случае возникновения проблем предоставления доступа обратитесь в СервисДеск компании. 
                </div>
                <div>
                    <Button onClick={handleClick} style={{margin: 'auto'}}>Регистрация без учета гео данных</Button>
                </div>
            </div>
        </Layout>
    )
};